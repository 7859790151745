@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.App {
  background-image: url(/static/media/fundo.33ba344d.jpg);
  background-attachment: fixed;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Topo {
  background-attachment: fixed;
  background-size: auto;
	max-width:100%;
  height: 300px;
  min-height: 150px;
  width:100%;
	height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.Menu {
  background-color: #0c1887;
  color: #fff;
	height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: line;
          flex-direction: line;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
}
.Teatro {
  background-color: rgb(233, 208, 96);
  height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  max-width:100%;
  min-width: 100%;
  min-height: 700px;
  font-size: calc(5px + 2vmin);
}
.Centro {
  width:40%;
  max-width:50%;
  min-width: 25%;
  min-height: 25px;
  display: -ms-flexbox;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
}
.Latesquerda {
	width:10%;
  min-height: 700px;
  height:auto;
  margin: 0px;
  text-align: left;
}
.Latdireita {
	width:10%;
  min-height: 700px;
  height:auto;
  margin: 0px;
  text-align: right;
}
.Imgcortina {
  max-height: 700px;
  min-height: 400px;
  height: auto;
  width: auto;
  margin: 0px;
}
.Imgtinzinho {
  max-height: 400px;
  min-height: 50px;
  width: auto;
  margin: 0px;
}

.Imgibi {
  max-height: 200px;
  min-height: 50px;
  width: auto;
  margin: 5px;
  border: #a80e28;
  border-style: double;
}
.Rodape {
  background-color: #df1033;
	height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 120px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  font-size: calc(5px + 2vmin);
}
.Barrarodape {
  background-color: #a80e28;
  color: #fff;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  font-size: calc(4px + 1vmin);
}
.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(8px + 2vmin);
}

.App-logo {
	max-width:50%;
  min-width: 30%;
  height:auto;
  width: 300px;
  -webkit-align-content: center;
          align-content: center;
  margin: 15px;
}

.Imgrodape {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 800px;
  margin: 5px;
}

.App-trilha {
	max-width:100%;
  min-width: 5%;
  height:auto;
  width: 145.55px;
}
.App-trilha2 {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 100px;
  margin: 5px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}


a:link, a:visited, a:active {
	text-decoration:none;
  color: #fff;
	}
a:hover {
	text-decoration:none; 
	color: #df1033; 
	}

.sidebar{
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #181C2e;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar div{
  background: url(/static/media/cover.6a293bc8.png);
  background-color: #181C2e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
}

.sidebar div{
  padding-top: 30px;
}

.sidebar div img {
  border-radius: 50%;
  display: block;
  margin:auto;
  width: 90px;
  height: 90px;
  -webkit-filter: drop-shadow(2px 3px 6px #121212);
  filter: drop-shadow(2px 3px 6px #121212);
  object-fit: cover;
}

.sidebar a{
  display: block;
  color: rgba(255,255,255, 0.7);
  padding: 16px;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  transition: ease-in-out .4s;
}

.sidebar a svg{
  margin-right: .5em;
}

.sidebar a:hover{
  background-color: #121212;
  color: #FFF;
}

.content{
  margin-left: 200px;
  padding: 1px 16px;
}

@media screen and (max-width: 700px){
  .sidebar{
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content{
    margin-left: 0;
  }

  .sidebar a{
    float: left;
  }
  .sidebar div{
    display: none;
  }

  .sidebar a svg{
    display: none;
  }
}


@media screen and (max-width: 400px){
  .sidebar a {
    text-align: center;
    float: none;
  }

  .sidebar a svg{
    display: none;
  }
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin: 1em 0;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: .8em;
}

.title span{
  margin-left: 1em;
  font-size: 1.5em;
}

.modal{
  position: fixed;
  top: 0;
  bottom:0;
  right: 0;
  left: 0;
  background: rgba(0,0,0, 0.6);
  z-index: 99;
}

.modal .container{
  position: fixed;
  max-width: 600px;
  top: 15%;
  left:0;
  right: 0;
  margin: 0 auto;
  padding: 4em 2rem;
  background-color: #FFF;
  box-shadow: 0 0 20px rgba(0,0,0, 0.8);
}

.close{
  background-color: #F65835;
  border:0;
  color: #FFF;
  position: absolute;
  top: 15px;
  left: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
}

.close svg{
  margin-right: 5px;
}

.modal h2{
  margin-bottom: 1.2em;
  font-size: 2em;
}

.modal span{
  font-weight: bold;
  font-size: 1.2em;
  color: #121212;
}

.modal span i{
  font-weight: 400;
  margin-right: 1em;
  padding: 2px 8px;
  border-radius: 3px;
}

.row{
  margin-bottom: 1em;
}

.modal p{
  padding-top: 0.5em;
  white-space: pre-wrap;
}

.container-center{
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #121212;
}

.login{
  background: #EAEAEC;
  width: 600px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.login-area{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #181c2e;
  width: 100%;
}

.login-area img{
  padding: 20px;
  width: 200px;
  height: auto;
}

form{
  margin-top: 1.5em;
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

form h1{
  text-align: center;
  margin-bottom: 0.5em;
  color: #181c2e;
}

.login input{
  margin-bottom: 15px;
  height: 35px;
  border:0;
  border-radius: 7px;
  padding: 10px;
  font-size: 15px;
  background-color: #FFF;
}

form button{
  height: 35px;
  border:0;
  border-radius: 7px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.3em;
}

.login a{
  margin: 1.5em 0;
  color: #000;
  cursor: pointer;
}




.dashboard{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}


.container{
  display: -webkit-flex;
  display: flex;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: .8em;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1em;
}

.label-avatar{
  width: 280px;
  height: 280px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  cursor: pointer;

}

.label-avatar input{
  display: none;
}

.label-avatar span{
  z-index: 99;
  position: absolute;
  opacity: 0.7;
  transition: all .5s;
}

.label-avatar span:hover{
  opacity: 1;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.form-profile img{
  margin-bottom: 1em;
  border-radius: 50%;
  object-fit: cover;
}

.form-profile label{
  margin-bottom: .5em;
  font-size: 1.4em;
}

.form-profile input, textarea, select{
  margin-bottom: 1em;
  padding: .7em;
  border:0;
  border-radius: 5px;
  max-width: 600px;
}

.form-profile input:disabled{
  cursor: not-allowed;
}

.form-profile button{
  max-width: 600px;
}

.logout-btn{
  padding: 8px 20px;
  background-color: transparent;
  border:1px solid #121212;
  border-radius: 5px;
  font-size: 1.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}




form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

form textarea{
  height: 105px;
  resize: none;
}

form input[type="radio"]{
  margin: 15px 0;
}

form input[type="radio"]:not(:first-child){
  margin-left: 15px;
}

.status span{
  padding-left: .5em;
  font-size: 1.3em;
}



.dashboard{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.dashboard span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}

.btn-more{
  margin: 1.5em 0;
  padding:  .5em 1em;
  height: 35px;
  border:0;
  border-radius: 5px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.1em;
}

@media screen and (max-width: 600px){
  table{
    border: 0;
  }

  table caption{
    font-size: 1.3em;
  }

  table thead{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table td{
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before{
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child{
    border-bottom: 0;
  }

}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus{
  outline: 0;
}

html, body, #root{
  height: 100%;
}

body{
  font: 14px 'Roboto', sans-serif;
  background-color: #EFEFEF;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}

button{
 cursor: pointer; 
}
