.App {
  background-image: url("../imagens/fundo.jpg");
  background-attachment: fixed;
  background-size: auto;
  background-color: black;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: column;
}

.Topo {
  background-attachment: fixed;
  background-size: auto;
	max-width:100%;
  height: 300px;
  min-height: 150px;
  width:100%;
	height:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Menu {
  background-color: #0c1887;
  color: #fff;
	height:auto;
  display: flex;
  flex-direction: line;
  justify-content: center;
  align-items: center;
  text-align: center;
  width:100%;
  max-width:100%;
  min-width: 50%;
  min-height: 50px;
  font-size: calc(7px + 2vmin);
}
.Teatro {
  background-color: rgb(233, 208, 96);
  height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 100%;
  min-height: 700px;
  font-size: calc(5px + 2vmin);
}
.Centro {
  width:40%;
  max-width:50%;
  min-width: 25%;
  min-height: 25px;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
}
.Latesquerda {
	width:10%;
  min-height: 700px;
  height:auto;
  margin: 0px;
  text-align: left;
}
.Latdireita {
	width:10%;
  min-height: 700px;
  height:auto;
  margin: 0px;
  text-align: right;
}
.Imgcortina {
  max-height: 700px;
  min-height: 400px;
  height: auto;
  width: auto;
  margin: 0px;
}
.Imgtinzinho {
  max-height: 400px;
  min-height: 50px;
  width: auto;
  margin: 0px;
}

.Imgibi {
  max-height: 200px;
  min-height: 50px;
  width: auto;
  margin: 5px;
  border: #a80e28;
  border-style: double;
}
.Rodape {
  background-color: #df1033;
	height:auto;
  display: flex;
  flex-direction: column;
  max-width:100%;
  min-width: 50%;
  min-height: 120px;
  align-items: center;
  align-content: center;
  font-size: calc(5px + 2vmin);
}
.Barrarodape {
  background-color: #a80e28;
  color: #fff;
	max-width:100%;
  min-width: 50%;
	height:auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(4px + 1vmin);
}
.Espaco {
  background-color: white;
	max-width:100%;
	height:auto;
  display: flex;
  flex-direction: row;
  max-width:100%;
  min-width: 50%;
  min-height: 10px;
  font-size: calc(8px + 2vmin);
}

.App-logo {
	max-width:50%;
  min-width: 30%;
  height:auto;
  width: 300px;
  align-content: center;
  margin: 15px;
}

.Imgrodape {
	max-width:100%;
  min-width: 30%;
  height:auto;
  width: 800px;
  margin: 5px;
}

.App-trilha {
	max-width:100%;
  min-width: 5%;
  height:auto;
  width: 145.55px;
}
.App-trilha2 {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 100px;
  margin: 5px;
}
.App-parceiro {
	max-width:100%;
  min-width: 50%;
  height:auto;
  width: 200px;
  margin: 50px;
}
.App-header {
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}


a:link, a:visited, a:active {
	text-decoration:none;
  color: #fff;
	}
a:hover {
	text-decoration:none; 
	color: #df1033; 
	}